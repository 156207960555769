// src/Search.js
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SearchForm from './components/SearchForm';
import SearchResults from './components/SearchResults';
import useProgress from './hooks/useProgress';
import useSearch from './hooks/useSearch';
import { AuthContext } from './AuthContext';
import './styles/Search.css';


const Search = () => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const [tripType, setTripType] = React.useState('dayTrip');

  const {
    searchParams,
    setSearchParams,
    searchResults,
    isLoading,
    error,
    handleSubmit,
  } = useSearch(tripType);

  const progress = useProgress(isLoading);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const departureAirport = queryParams.get('departureAirport');
    if (departureAirport) {
      setSearchParams((params) => ({ ...params, departureAirport }));
    }
  }, [location, setSearchParams]);

  const handleAirportChange = (e) => {
    setSearchParams((prevState) => ({ ...prevState, departureAirport: e.target.value }));
  };

  const handleDateChange = (date) => {
    setSearchParams((prevState) => ({ ...prevState, departureDate: date }));
  };

  const handleToggleChange = () => {
    setTripType((prevType) => (prevType === 'dayTrip' ? 'oneNightTrip' : 'dayTrip'));
  };

  const [displayCount, setDisplayCount] = React.useState(10);
  const loadMoreResults = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  return (
    <div className="search-page">
      <Helmet>
        <title>Search for an Extreme Day Trip</title>
      </Helmet>
      <Navbar />
      <div className="search-container">
        <SearchForm
          searchParams={searchParams}
          handleAirportChange={handleAirportChange}
          handleDateChange={handleDateChange}
          handleToggleChange={handleToggleChange}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          tripType={tripType}
        />
      </div>
      <SearchResults
        isLoading={isLoading}
        progress={progress}
        error={error}
        searchResults={searchResults}
        displayCount={displayCount}
        loadMoreResults={loadMoreResults}
        currentUser={currentUser}
      />
      <Footer />
    </div>
  );
};

export default Search;