// src/components/Footer.js

import React from 'react';
import '../styles/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faTwitter, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h4>Explore</h4>
        <ul>
          <li><Link to="/about">About Us</Link></li>
          <li><Link to="/search">Search for trips</Link></li>
          <li><Link to="/trips">View trips</Link></li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Resources</h4>
        <ul>
          <li><Link to="https://blog.extremedaytrips.com">Blog</Link></li>
          <li><Link to="/faq">FAQs</Link></li>
          <li><Link to="/closest-airports">Closest Airports</Link></li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Info</h4>
        <ul>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/terms">Terms</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Follow</h4>
        <div className="social-media-icons">
          <Link to="https://facebook.com/extremedaytrips"><FontAwesomeIcon icon={faFacebookF} className="fa" /></Link>
          <Link to="https://instagram.com/extreme.day.trips"><FontAwesomeIcon icon={faInstagram} className="fa" /></Link>
          <Link to="https://x.com/extremedaytrips"><FontAwesomeIcon icon={faTwitter} className="fa" /></Link>
          <Link to="https://youtube.com/@ExtremeDayTripz"><FontAwesomeIcon icon={faYoutube} className="fa" /></Link>
          <Link to="https://www.tiktok.com/@extreme.day.trips"><FontAwesomeIcon icon={faTiktok} className="fa" /></Link>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Extreme Day Trips Limited. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
