// src/PrivacyPolicy.js
import React from 'react';
import './styles/Home.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <div className="app-container">
      <Helmet>
        <title>Privacy Policy - Extreme Day Trips</title>
        <meta name="description" content="Read our commitment to your privacy and how we handle your data at Extreme Day Trips." />
        <meta name="keywords" content="privacy policy, data protection, Extreme Day Trips, personal information" />
        <meta property="og:title" content="Privacy Policy - Extreme Day Trips" />
        <meta property="og:description" content="Understand our approach to privacy and how we safeguard your personal data at Extreme Day Trips." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/privacy-policy" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Extreme Day Trips" />
        <meta name="twitter:description" content="Discover how Extreme Day Trips protects your privacy and handles your personal information." />
<meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
{/* Add any additional SEO related tags as needed */}
</Helmet>
<Navbar />
<header className="hero">
<h1>Our Privacy Policy</h1>
<p>Understanding your privacy and data protection at ExtremeDayTrips.com.</p>
</header>
<section className="policy-content">
<h2>Our Privacy Practices</h2>
<p>
<strong>1. Introduction</strong><br />
Welcome to Extreme Day Trips. We are committed to protecting the privacy and security of our customers' personal information. This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.extremedaytrips.com including any other media form, media channel, mobile website, or mobile application related or connected thereto.<br /><br />
<strong>2. Information We Collect</strong><br />
We may collect the following types of information:<br />
- Personal Identification Information: Name, email address, phone number, etc.<br />
- Travel Information: Travel preferences, booking and travel history etc.<br />
- Payment Information: Credit/debit card numbers, billing address, etc.<br />
- Technical Information: IP address, browser type, operating system, etc.<br /><br />
<strong>3. How We Use Your Information</strong><br />
We use the information we collect to:<br />
- Process your travel bookings.<br />
- Improve our website and services.<br />
- Communicate with you about your trip, our services, and special offers.<br />
- Conduct research and analysis.<br /><br />
<strong>4. Sharing Your Information</strong><br />
We may share your information with:<br />
- Airlines and travel providers for booking confirmation.<br />
- Third-party service providers for payment processing and fraud prevention.<br />
- Law enforcement or other government agencies if required by law.<br />
- We reserve the right to transfer your data to a third party in event of change of ownership or other<br />
<strong>5. Data Security</strong><br />
We implement various security measures

to maintain the safety of your personal information, such as encryption, firewalls, and secure socket layer technology.<br /><br />
<strong>6. Your Rights</strong><br />
You have the right to:<br />
- Access and receive a copy of your personal data.<br />
- Request correction or deletion of your personal data.<br />
- Object to processing of your data.<br /><br />
<strong>7. Changes to This Privacy Policy</strong><br />
We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.<br /><br />
<strong>8. Contact Us</strong><br />
If you have any questions about this privacy policy, please contact us at:<br />
- Email: hello@extremedaytrips.com<br />
<br />
<strong>9. Consent</strong><br />
By using our website, you hereby consent to our privacy policy and agree to its terms.<br /><br />
<strong>10. Cookies and Similar Technologies</strong><br />
We use cookies and similar tracking technologies to track activity on our website and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.<br /><br />
<strong>11. International Data Transfer</strong><br />
Your information, including personal data, may be transferred to — and maintained on — computers located outside of your country where the data protection laws may differ from those of your jurisdiction.<br /><br />
<strong>12. Children's Privacy</strong><br />
Our website does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from children. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.<br /><br />
<strong>13. Third-Party Websites</strong><br />
Our website may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit.<br /><br />
<strong>14. Your Consent</strong><br />
By using our site, you consent to our website's privacy policy.<br /><br />
<strong>15. Contacting Us</strong><br />
If there are any questions regarding this privacy policy, you may contact us using the information below.<br />
Email: hello@extremedaytrips.com<br />
</p>
</section>
<Footer />
</div>
);
};

export default PrivacyPolicy;


