import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { app } from '../firebaseConfig';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import '../styles/style.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const goBack = () => {
    navigate('/');
  };

  const handleLogin = async () => {
    setError('');
    try {
      const auth = getAuth(app);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(`Login failed, please try again!`);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // Handle successful login
      navigate('/'); // Redirect to dashboard or desired page
    } catch (error) {
      setError('Google sign-in failed. Please try again.');
      console.error(error);
    }
  };

  const handleFacebookSignIn = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // Handle successful login
      navigate('/'); // Redirect to dashboard or desired page
    } catch (error) {
        let errorMessage = 'Facebook sign-in failed. Please try again.';

        // Handle specific error codes
        if (error.code === 'auth/account-exists-with-different-credential') {
            errorMessage = 'An account already exists with the same email address but different sign-in credentials. Please use a different sign-in method.';
        } else if (error.code === 'auth/popup-closed-by-user') {
            // likely not an error, prompt them to try again
            errorMessage = 'Facebook sign-in popup was closed prematurely, please try again and only click Continue once.';
        } else if (error.code === 'auth/cancelled-popup-request') {
            errorMessage = 'Only one popup request is allowed at one time. Please try again.';
        } else if (error.code === 'auth/operation-not-allowed') {
            errorMessage = 'Facebook sign-in is not enabled. Please contact support.';
        }

        setError(errorMessage);
        console.error('Facebook sign-in error:', error);
    }
  };

  const handleAppleSignIn = async () => {
    const provider = new OAuthProvider('apple.com');

    try {
      const result = await signInWithPopup(auth, provider);
      navigate('/'); // Redirect to dashboard or desired page
    } catch (error) {
      setError('Sign in with Apple failed. Please try again.');
      console.error('Error with Apple Sign-In:', error);
    }
  };

  const navigateToResetPassword = () => {
    navigate('/password-reset');
  };

  return (
    <div className="welcome-container">
      <Helmet>
        <title>Login - Extreme Day Trips</title>
        <meta name="description" content="Log in to your Extreme Day Trips account to access and book unique day-long adventures. Join our community of adventure enthusiasts." />
        <meta name="keywords" content="login, Extreme Day Trips, account access, book adventures, travel community" />
        <meta property="og:title" content="Login - Extreme Day Trips" />
        <meta property="og:description" content="Access your Extreme Day Trips account to manage bookings and connect with a global community of explorers." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/login" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login - Extreme Day Trips" />
        <meta name="twitter:description" content="Log in to Extreme Day Trips and embark on your next day-long adventure. Connect with fellow adventure enthusiasts." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
        {/* Add any additional SEO related tags as needed */}
      </Helmet>
      <div className="back-arrow" onClick={goBack}>&#x2190;</div>
      <div className="welcome-content">
        <h1>Login to Extreme Day Trips</h1>
        {error && <div className="error-message">{error}</div>}
             {/* Social login options */}
      <fieldset>
        <legend>&nbsp;Sign In with Social Sign In&nbsp;</legend>
        <div>
          <img 
          src="googlesigninwith.svg" 
          alt="Sign in with Google" 
          style={{ cursor: 'pointer', height: '32px', marginTop: '12px' }} 
          onClick={handleGoogleSignIn} 
          />         
          <br/>
          {/* <img 
          src="facebooklogin.png" 
          alt="Sign in with Facebook" 
          style={{ cursor: 'pointer', height: '38px' }} 
          onClick={handleFacebookSignIn} 
          />         
          <br/> */}
          <img 
          src="apple-login.png" 
          alt="Sign in with Apple" 
          style={{ cursor: 'pointer', height: '30px', marginBottom: '16px' }} 
          onClick={handleAppleSignIn} 
          />         
        </div>
      </fieldset>
      <br/>
      <fieldset>
        <legend>&nbsp;Or Sign In With Email&nbsp;</legend>
      <div className="flex-row" style={{padding:6}}>
      <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field half-width"
          required
        />
     </div>
     <div className="flex-row" style={{padding:6}}>
       <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field half-width"
          required
        />
      </div>
      
      <div className="flex-row" style={{padding:6}}>
        <button onClick={handleLogin} className="welcome-button">Login</button>      
      </div>
      
      <div className="flex-row" style={{ padding: 6, marginTop: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <a href="password-reset" style={{ fontSize: '12px' }}>
              Forgotten Your Password?
          </a>
      </div>
      </fieldset>
        
      </div>
    </div>
  );
};

export default Login;