// src/components/SearchResults.js
import React from 'react';
import FlightInfoCard from './FlightInfoCard';
import { Link } from 'react-router-dom';
import '../styles/Search.css';

const SearchResults = ({
  isLoading,
  progress,
  error,
  searchResults,
  displayCount,
  loadMoreResults,
  currentUser,
}) => {
  if (isLoading) {
    return (
      <div className="search-results">
        <div className="loader-container">
          <div className="progress-bar-container">
            <div
              className="progress-bar-fill"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="progress-text">Loading {Math.round(progress)}%</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="search-results">
      {searchResults.slice(0, displayCount).map((flight, index) => (
        <FlightInfoCard key={index} tripData={flight} />
      ))}
      {!isLoading && displayCount < searchResults.length && (
        currentUser ? (
          <button onClick={loadMoreResults} className="load-more-button">
            Load More
          </button>
        ) : (
          <div>
            <button className="load-more-button disabled" disabled>
              Load More
            </button>
            <p id="sign-in-prompt">
              To view more than 10 trips,
              <Link to="/Welcome" className="sign-in-link"> create a free account</Link>.
            </p>
          </div>
        )
      )}
    </div>
  );
};

export default SearchResults;