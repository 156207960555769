// src/StepOne.js
import React, { useState } from 'react';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';
// import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import '../styles/style.css';

const StepOne = ({ nextStep, handleChange, values, setUserDetails }) => {
  const [error, setError] = useState('');
  // const navigate = useNavigate(); // Use navigate for redirection


  // Function to check if all fields are filled
  const handleNextClick = () => {
    if (!values.firstName || !values.lastName || !values.email || !values.password) {
      setError('Please fill out all fields.'); // Set error message
    } else if (values.email && !values.email.includes('@')) { // check for valid email
      setError('Please enter a valid email address.');
    } else {
      setError(''); // Clear error message
      nextStep();
    }
  };

   const handleFacebookSignIn = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Extract details and set user details
      const email = user.email;
      const displayName = user.displayName || '';
      let [firstName, lastName] = displayName?.split(' ');
      // if firstname and lastname are not available set default value
      if (!firstName) {
        firstName = 'Unknown';
      }
      if (!lastName) {
        lastName = 'Unknown';
      }
      setUserDetails({ email, firstName, lastName });
    } catch (error) {
        let errorMessage = 'Facebook sign-in failed. Please try again.';

        // Handle specific error codes
        if (error.code === 'auth/account-exists-with-different-credential') {
            errorMessage = 'An account already exists with the same email address but different sign-in credentials. Please use a different sign-in method.';
        } else if (error.code === 'auth/popup-closed-by-user') {
            // likely not an error, prompt them to try again
            errorMessage = 'Facebook sign-in popup was closed prematurely, please try again and only click Continue once.';
        } else if (error.code === 'auth/cancelled-popup-request') {
            errorMessage = 'Only one popup request is allowed at one time. Please try again.';
        } else if (error.code === 'auth/operation-not-allowed') {
            errorMessage = 'Facebook sign-in is not enabled. Please contact support.';
        }

        setError(errorMessage);
        console.error('Facebook sign-in error:', error);

    }
  };

  const handleGoogleSignIn = async () => { 
   const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const displayName = user.displayName || '';
      const [firstName, lastName] = displayName.split(' ');

      // Set user details and go directly to Step Two
      setUserDetails({ email, firstName, lastName });
    } catch (error) {
      setError('Google sign-in failed. Please try again.');
      console.error(error);
    }
  };

   const handleAppleSignIn = async () => {
    const provider = new OAuthProvider('apple.com');

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Extract user information
      const email = user.email;
      const displayName = user.displayName || '';
      const [firstName, lastName] = displayName.split(' ') || ['', ''];

      // Set user details and navigate to Step Two
      setUserDetails({ email, firstName, lastName });
      nextStep();
    } catch (error) {
      setError('Sign in with Apple failed. Please try again.');
      console.error('Error with Apple Sign-In:', error);
    }
  };

  return (
    <div className="welcome-content">
      <h1>Create Your Free Account</h1>
      {error && <div className="error-message">{error}</div>} {/* Display error message if it exists */}
   
      {/* Social login options */}
      <fieldset>
        <legend>&nbsp;Continue with Social Sign In&nbsp;</legend>
        <div>
          <img 
          src="googlesigninwith.svg" 
          alt="Sign in with Google" 
          style={{ cursor: 'pointer', height: '32px', marginTop: '12px' }} 
          onClick={handleGoogleSignIn} 
          />         
          <br/>
          {/* <img 
          src="facebooklogin.png" 
          alt="Sign in with Facebook" 
          style={{ cursor: 'pointer', height: '38px' }} 
          onClick={handleFacebookSignIn} 
          />         
          <br/> */}
          <img 
          src="apple-login.png" 
          alt="Sign in with Apple" 
          style={{ cursor: 'pointer', height: '30px', marginBottom: '16px' }} 
          onClick={handleAppleSignIn} 
          />         
        </div>
      </fieldset>
      <br/>
      <fieldset>
        <legend>&nbsp;Or Continue With Email&nbsp;</legend>
      <div className="flex-row" style={{padding:6, marginTop:6}}>
        <input
          type="text"
          placeholder="First Name *"
          onChange={handleChange('firstName')}
          value={values.firstName}
          className="input-field half-width"
          required
        />
        <input
          type="text"
          placeholder="Last Name *"
          onChange={handleChange('lastName')}
          value={values.lastName}
          className="input-field half-width"
          required
        />
      </div>
      <div className="flex-row" style={{padding:6}}>
      <input
        type="email"
        placeholder="Email Address *"
        onChange={handleChange('email')}
        value={values.email}
        className="input-field"
        required
      />
     </div>
     <div className="flex-row" style={{padding:6}}>
      <input
        type="password"
        placeholder="Password *"
        onChange={handleChange('password')}
        value={values.password}
        className="input-field"
        required
      />
      </div>
      <div className="flex-row" style={{padding:6}}>
        <button onClick={handleNextClick} className="welcome-button">Next</button>
      </div>
      </fieldset>
    </div>


    
  );
};

export default StepOne;
