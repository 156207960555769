// src/Terms.js
import React from 'react';
import './styles/Home.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';

const Terms = () => {
  return (
    <div className="app-container">
      <Helmet>
        <title>Terms and Conditions - Extreme Day Trips</title>
        <meta name="description" content="Understand the terms and conditions for using the Extreme Day Trips website." />
        <meta name="keywords" content="terms and conditions, Extreme Day Trips, user agreement" />
        <meta property="og:title" content="Terms and Conditions - Extreme Day Trips" />
        <meta property="og:description" content="Read the terms and conditions governing your use of the Extreme Day Trips website and services." />
        {/* Additional SEO tags */}
      </Helmet>
      <Navbar />
      <header className="hero">
        <h1>Terms and Conditions</h1>
        <p>Important information about your use of ExtremeDayTrips.com.</p>
      </header>
      <section className="policy-content">
        <h2>Agreement to Terms</h2>
        <p>
          By accessing and using the Extreme Day Trips website, you agree to be bound by these terms and conditions as well as our privacy policy. If you do not agree with any part of these terms, you must not use our website.<br /><br />
<strong>1. Use of Website</strong><br />
- This website is intended for users who are at least 18 years old.<br />
- The content and services provided on this website are for your personal and non-commercial use.<br /><br />
<strong>2. Intellectual Property Rights</strong><br />
- All content on this website, including text, graphics, logos, images, and software, is the property of Extreme Day Trips or its content suppliers and protected by copyright and intellectual property laws.<br /><br />
<strong>3. User Conduct</strong><br />
- You must not use the website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website.<br />
- You must not use the website in any way that is unlawful, illegal, fraudulent, or harmful.<br /><br />
<strong>4. Booking and Cancellation Policy</strong><br />
- When you make a booking through our website, you agree to enter into a contract with the respective travel service provider.<br />
- Cancellation policies and fees are determined by the individual travel service providers.<br /><br />
<strong>5. Limitation of Liability</strong><br />
- Extreme Day Trips will not be liable for any indirect, special, or consequential loss or damage arising under these terms and conditions or in connection with our website.<br /><br />
<strong>6. Changes to Terms and Conditions</strong><br />
- We reserve the right to make changes to these terms and conditions at any time. Your continued use of the website after any such changes constitutes your acceptance of the new terms.<br /><br />
<strong>7. Governing Law and Jurisdiction

</strong><br />
- These terms and conditions are governed by and construed in accordance with the laws of the United Kingdom.<br />
- Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of the United Kingdom.<br /><br />
<strong>8. Contact Information</strong><br />
If you have any questions or concerns about these terms and conditions, please contact us at:<br />
Email: support@extremedaytrips.com<br /><br />
<strong>9. Disclaimer</strong><br />
The information and services provided on Extreme Day Trips are provided 'as is' without any warranties, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.<br /><br />
<strong>10. Acceptance of Terms</strong><br />
By using this website, you signify your acceptance of these terms and conditions. If you do not agree to these terms, please do not use our website.<br />
</p>
</section>
<Footer />
</div>
);
};

export default Terms;
