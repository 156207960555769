// PasswordReset.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/style.css';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { app } from '../firebaseConfig';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setMessage('');
    if (!email || !email.includes('@')) {
      setMessage('Please enter a valid email address.');
      return;
    }
    try {
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      setMessage('Check your email for the password reset link.');
      setEmailSent(true); // Set the flag to true when email is sent
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="welcome-container">
      <div className="back-arrow" onClick={() => navigate('/login')}>&#x2190;</div>
      <div className="welcome-content">
        <h1>Reset Your Password</h1>
        {message && <div className={emailSent ? "success-message" : "error-message"}>{message}</div>}
        {!emailSent && (
          <>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              required
            />
            <button onClick={handlePasswordReset} className="welcome-button">Send Reset Email</button>
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;