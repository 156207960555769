// src/hooks/useSearch.js
import { useState } from 'react';
import { fetchFlights } from '../utilities/fetchFlights';

const useSearch = (tripType) => {
  const [searchParams, setSearchParams] = useState({
    departureAirport: '',
    departureDate: '',
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);

  const performSearch = async (dates) => {
    setIsLoading(true);
    let results = [];
    for (const departureDate of dates) {
      let returnDate = departureDate;
      if (tripType === 'oneNightTrip') {
        const departureDateObj = new Date(departureDate);
        departureDateObj.setDate(departureDateObj.getDate() + 1);
        returnDate = departureDateObj.toISOString().split('T')[0];
      }

      let fetchParams = {
        departureAirport: searchParams.departureAirport,
        departureDate: departureDate,
        returnDate: returnDate,
      };

      if (tripType === 'oneNightTrip') {
        Object.assign(fetchParams, {
          dtimeFrom: '04:00',
          dtimeTo: '17:00',
          retDtimeFrom: '06:00',
          retDtimeTo: '23:00',
          nightsInDstFrom: '1',
          nightsInDstTo: '2',
        });
      }

      const flights = await fetchFlights(
        fetchParams,
        true,
        setIsLoading,
        setError,
        setSearchResults,
        setErrorMessage,
        searchPerformed,
        setWarningMessage
      );
      results = results.concat(flights);
    }
    setSearchResults(results.sort((a, b) => a.price - b.price));
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSearchResults([]);

    if (searchParams.departureAirport) {
      const dates = [searchParams.departureDate];
      await performSearch(dates);
    }
  };

  return {
    searchParams,
    setSearchParams,
    searchResults,
    isLoading,
    error,
    handleSubmit,
  };
};

export default useSearch;
