// src/Contact.js
import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles/Contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact Us - Extreme Day Trips</title>
        <meta name="description" content="Reach out to us with your questions or messages. Contact Extreme Day Trips' team for support, inquiries, or feedback." />
        <meta name="keywords" content="contact, support, inquiry, feedback, Extreme Day Trips, adventure, day trips" />
        <meta property="og:title" content="Contact Us - Extreme Day Trips" />
        <meta property="og:description" content="Looking for support or have a question? Contact Extreme Day Trips' team directly through this form." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/contact" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Extreme Day Trips" />
        <meta name="twitter:description" content="Need support or have a query? Reach out to Extreme Day Trips' team via our contact form." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
      </Helmet>
      <Navbar />
      <header className="contact-hero">
        <h1>Contact Us</h1>
        <p>Have any questions or need to get in touch with our team? Send us a message.</p>
      </header>
      <div className="contact-form-container">
        <form className="contact-form" name="contact" method="POST" data-netlify="true" netlify>
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <button type="submit">Send Message</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
