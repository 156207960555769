// src/pages/closest-airport.js
import React from 'react';
import '../styles/Home.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import AirportCard from '../components/AirportCard';

const airports = [
    {
        name: "Gibraltar",
        distance: "1.5 kilometers",
        cost: "£1.50/€2.40 for a five-minute bus journey, or walk for free(!)",
        description: "Direct flights from several cities in the UK. Enjoy the beach and soak up the sun."
    },
    {
        name: "Tallinn, Estonia",
        distance: "4 kilometers",
        cost: "€2 for a 15-minute bus or tram journey",
        description: "A compact, accessible city with a fairytale air. Don't miss the Christmas market."
    },
    {
        name: "Olbia, Italy",
        distance: "4 kilometers",
        cost: "€1 for a 10-minute bus journey",
        description: "An underrated Italian city on Sardinia's northeast coast. Small, relaxed, and historical."
    },
    {
        name: "Pisa, Italy",
        distance: "2.2 kilometers",
        cost: "€5 for the automated people mover",
        description: "Picture-perfect postcard Italian city with the iconic Leaning Tower."
    },
    {
        name: "Vilnius, Lithuania",
        distance: "5.9 kilometers",
        cost: "€1 for a 20-minute bus journey",
        description: "Home to the largest medieval Old Town in Central and Eastern Europe. Great for budget travel."
    },
    {
        name: "Rotterdam, Netherlands",
        distance: "6.8 kilometers",
        cost: "€2.02 for a 20-minute bus journey",
        description: "Quirky modern architecture, vibrant art, and fashion scene. Close to The Hague."
    },
    {
        name: "Salzburg, Austria",
        distance: "6.8 kilometers",
        cost: "€2.50 for a 20-minute bus journey",
        description: "Historical city with a UNESCO World Heritage Old Town and nearby ski slopes."
    },
    {
        name: "Copenhagen, Denmark",
        distance: "7 kilometers",
        cost: "38kr./€5.10 for a 15-minute metro journey",
        description: "Easily accessible museums and cultural quarters in a lively and cosmopolitan capital."
    },
    {
        name: "Dublin, Ireland",
        distance: "10 kilometers",
        cost: "€7 for a 50-minute bus journey",
        description: "Well-connected by flights, with famously welcoming pubs and bars."
    },
    {
        name: "Marrakech, Morocco (Marrakesh Menara Airport)",
        distance: "5 km",
        cost: "Bus: 30 Moroccan Dirham, Shuttle: 30 Moroccan Dirham",
        description: "Short distance to city center with efficient bus service. Shuttle buses offer a comfortable ride."
    },
    {
        name: "Lisbon, Portugal (Lisbon Portela Airport)",
        distance: "5.9 km",
        cost: "Aerobus Shuttle: €3.50, Bus: €1.75, Metro: €1.90, Taxi: Around €10",
        description: "A variety of transport options, including an Aerobus shuttle and a quick metro service."
    },
    {
        name: "Bratislava, Slovakia (Bratislava Airport)",
        distance: "9 km",
        cost: "Bus: €0.9, Taxi: €15-€30",
        description: "Affordable bus journey to the city center. Taxis are more expensive, especially when hailed from the street."
    },
    {
        name: "Riga, Latvia (Riga International Airport)",
        distance: "10 km",
        cost: "Bus: €1.15-€2, Taxi: €10-€15, Mini Bus: From €5",
        description: "Buses offer a cheap ride, while mini buses can be a quicker, albeit slightly more expensive option."
    },
    {
        name: "Krakow, Poland (John Paul II International Airport Kraków–Balice)",
        distance: "13km",
        cost: "Bus: 4zł, Taxi: 35zł-90zł",
        description: "Bus service is extremely affordable. Taxis vary in price, with pre-booked ones being cheaper."
    },
    {
        name: "Manchester, UK (Manchester Airport)",
        distance: "13.9 km",
        cost: "Bus: Varies, Train: £4.20, Tram: £4.20-£5, Taxi: £15",
        description: "A wide range of public transport options available, including trams and trains."
    },
    {
        name: "Berlin, Germany (Schönefeld Airport)",
        distance: "18 km",
        cost: "Bus: €3, Train: €3, Taxi: €40",
        description: "Despite being further away, Schönefeld offers affordable bus and train options to reach the city center."
    },
    {
        name: "Nice, France (Nice Côte d'Azur)",
        distance: "7.6 km",
        cost: "From £1.59/€1.80",
        description: "Quick five-minute transfer to the city, offering convenience and efficiency."
    },
    {
        name: "Lanzarote, Spain",
        distance: "7 km",
        cost: "From £1.24/€1.41",
        description: "Ten-minute transfer to the main areas, ideal for quick access after your flight."
    },
    {
        name: "Málaga, Spain (Málaga-Costa del Sol)",
        distance: "8 kmr",
        cost: "From £1.59/€1.80",
        description: "12-minute transfer to the heart of Málaga, perfect for beach lovers."
    },
    {
        name: "Frankfurt, Germany",
        distance: "13 km",
        cost: "From £4.24/€4.81",
        description: "Ten-minute transfer to downtown Frankfurt, connecting you quickly to this financial hub."
    },
    {
        name: "Nürnberg, Germany",
        distance: "8 km",
        cost: "From £2.65/€3.01",
        description: "12-minute transfer, convenient for exploring this historic city."
    },
    {
        name: "Cologne, Germany (Cologne Bonn)",
        distance: "15.4 km",
        cost: "From £2.12/€2.41",
        description: "13-minute transfer to Cologne, known for its impressive cathedral and vibrant culture."
    },
    {
        name: "Prague, Czech Republic",
        distance: "15 km",
        cost: "From £0.79/€0.90",
        description: "15-minute transfer brings you quickly to the heart of this charming city."
    },
    {
        name: "Leipzig, Germany (Leipzig-Halle)",
        distance: "21 km",
        cost: "From £3.97/€4.50",
        description: "13-minute transfer to Leipzig, a city of music and history."
    },
    {
        name: "Amsterdam, Netherlands (Schiphol)",
        distance: "21 km",
        cost: "From £3.71/€4.21",
        description: "14-minute transfer to the center of Amsterdam, perfect for exploring its canals and culture."
    },
    {
        name: "Geneva, Switzerland",
        distance: "4 km",
        cost: "From €2.60",
        description: "Visit Lake Geneva for a wonderful view"
    },
    {
        name: "Warsaw, Poland",
        distance: "10 km",
        cost: "From €8",
        description: "Bus and train transfer options available"
    },
    {
        name: "Milan, Italy (Linate)",
        distance: "8 km",
        cost: "From €3",
        description: "12 minutes from Linate airport to the city centre. Note that Malpensa and Bergamo are much further away."
    },
    {
        name: "Barcelona, Spain (El Prat)",
        distance: "12 km",
        cost: "From €5.90",
        description: "35 minutes from the airport to the city centre on the bus"
    },
    {
        name: "Brussels, Belgium",
        distance: "12 km",
        cost: "From €8.60",
        description: "Take the train direct from Brussels airport to the city centre in 20 minutes. Note that Charleroi airport is significantly further away."
    },
    {
        name: "Vienna, Austria",
        distance: "20 km",
        cost: "From €29.80",
        description: "Take the train direct from Vienna airport to the city centre in just 16 minutes"
    },
    {
        name: "Zurich, Switzerland",
        distance: "10 km",
        cost: "From €5.60",
        description: "There is a train station right underneath the airport that goes direct to the city centre in 10 minutes"
    },
    {
        name: "Seville, Spain",
        distance: "11 km",
        cost: "From €3",
        description: "There is a bus to the city centre that takes around 30 minutes. It is recommended to check the schedule in advance."
    },
    {
        name: "Venice, Italy",
        distance: "13 km",
        cost: "From €20",
        description: "Take the bus to Venice in around 20 minutes. Note that most of Venice is not accessible by car. There is also a ferry service."
    },
    {
        name: "Bologna, Italy",
        distance: "9.7 km",
        cost: "From €18.40",
        description: "The monorail connects to Bologna centre in just 7 minutes."
    },
    {
        name: "Naples, Italy",
        distance: "4.8 km",
        cost: "From €10",
        description: "Take the bus into Naples centre in just 15 minutes. Enjoy a neopolitan pizza in Naples!"
    },
    {
        name: "Faro, Portugal",
        distance: "10.5 km",
        cost: "From €4.50",
        description: "Take the bus to Faro in just 20 minutes. Bolt is also easy and cheap within the Algarve."
    },
    {
        name: "Budapest, Hungary",
        distance: "25 km",
        cost: "From €3",
        description: "The bus to central Budapest takes 35 minutes. It can get busy so limited bags is recommended."
    },
    {
        name: "Corfu, Greece",
        distance: "3.5 km",
        cost: "From €2.40",
        description: "Get to the centre of the old town in just 15 minutes!"
    },
    {
        name: "Belfast, United Kingdom",
        distance: "5 km",
        cost: "From £2.60",
        description: "Belfast City Airport is just 10 minutes away by bus. Please note that Belfast International is significantly further away."
    },
    {
        name: "Santander, Spain",
        distance: "5 km",
        cost: "From €2.15",
        description: "The bus to Santander from the airport takes just 15 minutes"
    },
];

// Function to extract numeric distance value from the distance string.
const getNumericDistance = (distanceStr) => {
  const distanceParts = distanceStr.split(' ');
  return parseFloat(distanceParts[0]);
};

// Sort airports by distance.
const sortedAirports = airports.sort((a, b) => {
  return getNumericDistance(a.distance) - getNumericDistance(b.distance);
});

const AirportInfo = () => (
    <div className="airport-info-container">
        {sortedAirports.map((airport, index) => (
            <AirportCard key={index} airport={airport} />
        ))}
    </div>
);

const ClosestAirport = () => {
  return (
    <div className="app-container">
      <Helmet>
        {/* Helmet content */}
      </Helmet>
      <Navbar />
      <header className="hero">
        <h1>Closest Airports</h1>
        <p>Find the closest airports to the city center in Europe and start your adventure with ease!</p>
      </header>
      <section className="content">
        <AirportInfo />
      </section>
      <Footer />
    </div>
  );
};

export default ClosestAirport;