// src/utilities/useImageFetcher.js
import { useState, useEffect } from 'react';

const useImageFetcher = (city) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const localCities = [
      'amsterdam', 'baden baden', 'barcelona', 'belfast', 'berlin', 'bratislava', 
      'brussels', 'bucharest', 'budapest', 'cluj-napoca', 'cologne', 'copenhagen', 
      'dortmund', 'dublin', 'edinburgh', 'eindhoven', 'faro', 'geneva', 'gibraltar', 
      'glasgow', 'gothenburg', 'helsinki', 'innsbruck', 'killarney', 'krakow', 
      'lanzarote', 'larnaca', 'las palmas', 'leipzig', 'lisbon', 'london', 'luxembourg', 
      'lyon', 'madrid', 'marrakech', 'marrakesh', 'marseille', 'milan', 'nice', 
      'nuremberg', 'oslo', 'palma, majorca', 'paris', 'paphos', 'porto', 'prague', 
      'riga', 'rome', 'rotterdam', 'salzburg', 'santander', 'seville', 'southampton', 
      'stockholm', 'tallinn', 'tenerife', 'tirana', 'toulouse', 'trieste', 'venice', 
      'verona', 'vienna', 'vilnius', 'warsaw', 'zurich'
    ];


    const fetchImage = async () => {
      if (localCities.includes(city.toLowerCase())) {
        // Use local image if the city matches one of the local image filenames
        setImageUrl(`cities/${city.toLowerCase()}.jpg`);
      } else {
        // try {
        //   // Fetching image using Teleport API
        //   const response = await fetch(`https://api.teleport.org/api/urban_areas/slug:${city.toLowerCase()}/images/`);
        //   const data = await response.json();

        //   if (data.photos && data.photos.length > 0) {
        //     setImageUrl(data.photos[0].image.web); // Using the web resolution image
        //   } else {
        //     throw new Error('Image URL not found');
        //   }
        // } catch (error) {
        //   console.error('Failed to fetch image:', error);
          setImageUrl('default.jpg'); // Set a default image path
        // }
      }
    };

    if (city && city !== 'default-city') {
      fetchImage();
    } else {
      setImageUrl('default.jpg'); // Set a default image path
    }
  }, [city]);

  return imageUrl;
};

export default useImageFetcher;
