// src/hooks/useProgress.js
import { useState, useEffect } from 'react';

const useProgress = (isLoading) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let intervalId;
    if (isLoading) {
      setProgress(0);
      const startTime = Date.now();
      intervalId = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const percentage = Math.min((elapsed / 3000) * 100, 100); // Adjust as needed
        setProgress(percentage);
        if (percentage >= 100) {
          clearInterval(intervalId);
        }
      }, 100);
    } else {
      setProgress(0);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoading]);

  return progress;
};

export default useProgress;
